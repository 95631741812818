import React from "react";
import "./App.css";

const App: React.FC = () => {
  return (
    <div className="App">
      <h1>This page is intentionally left blank.</h1>
      <h2>(for now)</h2>
    </div>
  );
};

export default App;
